"use client"

import { HeadphonesIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { DropdownMenuItem } from "@/components/ui/dropdown-menu"

interface SupportLinkProps {
  url?: string
}

const defaultSupportUrl = "http://blattnertech.haloitsm.com/portal"

export function SupportButton({ url = defaultSupportUrl }: SupportLinkProps) {
  return (
    <Button onClick={() => window.open(url, "_blank")}>
      Open Support Portal
    </Button>
  )
}

export function SupportLink({ url = defaultSupportUrl }: SupportLinkProps) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 underline"
    >
      Open Support Portal
    </a>
  )
}

export function SupportDropdownMenuItem({
  url = defaultSupportUrl,
}: SupportLinkProps) {
  return (
    <DropdownMenuItem
      onClick={() => window.open(url, "_blank")}
      className="flex items-center"
    >
      <HeadphonesIcon className="mr-2 h-4 w-4" />
      <span>Support Portal</span>
    </DropdownMenuItem>
  )
}
